"use client";
import { useEffect } from "react";
import { useRouter } from "next/navigation";

const ErrorPage = ({
    error,
    reset,
}: {
    error: Error & { digest?: string };
    reset: () => void;
}) => {
    const router = useRouter();

    // Optional: Auto-redirect to home after 10 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            router.push("/");
        }, 10000); // Redirect after 10 seconds
        return () => clearTimeout(timer); // Clear timer on component unmount test
    }, [router]);

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 px-4">
            <div className="bg-white shadow-lg rounded-lg p-8 max-w-lg w-full text-center">
                <svg
                    width="90px"
                    height="90px"
                    viewBox="0 0 15 15"
                    fill="none"
                    className="mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.4449 0.608765C8.0183 -0.107015 6.9817 -0.107015 6.55509 0.608766L0.161178 11.3368C-0.275824 12.07 0.252503 13 1.10608 13H13.8939C14.7475 13 15.2758 12.07 14.8388 11.3368L8.4449 0.608765ZM7.4141 1.12073C7.45288 1.05566 7.54712 1.05566 7.5859 1.12073L13.9798 11.8488C14.0196 11.9154 13.9715 12 13.8939 12H1.10608C1.02849 12 0.980454 11.9154 1.02018 11.8488L7.4141 1.12073ZM6.8269 4.48611C6.81221 4.10423 7.11783 3.78663 7.5 3.78663C7.88217 3.78663 8.18778 4.10423 8.1731 4.48612L8.01921 8.48701C8.00848 8.766 7.7792 8.98664 7.5 8.98664C7.2208 8.98664 6.99151 8.766 6.98078 8.48701L6.8269 4.48611ZM8.24989 10.476C8.24989 10.8902 7.9141 11.226 7.49989 11.226C7.08567 11.226 6.74989 10.8902 6.74989 10.476C6.74989 10.0618 7.08567 9.72599 7.49989 9.72599C7.9141 9.72599 8.24989 10.0618 8.24989 10.476Z"
                        fill="#ff0000"
                    />
                </svg>

                <h1 className="text-3xl font-bold font-inter text-gray-800 mt-5">
                    Oops! Something went wrong
                </h1>
                <p className="mt-3 font-inter text-gray-600">
                    {error.message || "We encountered an unexpected error."}
                </p>

                <div className="mt-6 space-x-4">
                    <button
                        onClick={() => reset()}
                        className="bg-red-500 font-inter hover:bg-red-600 text-white py-2 px-6 rounded-lg transition"
                    >
                        Try Again
                    </button>
                    <button
                        onClick={() => router.push("/")}
                        className="bg-gray-300 font-inter hover:bg-gray-400 text-gray-700 py-2 px-6 rounded-lg transition"
                    >
                        Back to Home
                    </button>
                </div>
                <p className="mt-5 text-sm text-gray-400">
                    Auto-redirecting to the home page in 10 seconds...
                </p>
            </div>
        </div>
    );
};

export default ErrorPage;
